import {
  RECEIVE_CATEGORIES,
  REFRESH_STORE
} from '../constants/action-types';

const initialState = {
  categories: []
}

const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_CATEGORIES:
      return { ...state, categories: action.categories };

    case REFRESH_STORE:
      return initialState;

    default:
      return state;
  }
};

export default categoriesReducer;