import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getToken, getUser } from '../utils';

const PublicRoute = ({ children }) => {
  const user = getUser();
  const token = getToken();
  let location = useLocation();

  if (user && token) {
    return <Navigate to={location.state ? location.state.from : user.role === "employee" ? "/sprzedawca/zapytania" : "/klient/moje-oferty"} state={location.state} />;
  }

  return children;
};

export default PublicRoute;