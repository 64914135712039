import * as types from '../constants/action-types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

/**
 * Dispatch Refresh Store
 * @param { Number } demo
 */
 export const refreshStore = (current) => dispatch => {
    dispatch(refreshUnSafe(current));
}
/**
 * Refresh Store
 * @param {*} current 
 */
export const refreshUnSafe = (current) => ({
    type: types.REFRESH_STORE,
    current
})

/********************* Wishlist Action *********************/

/**
 * Add item to Wishlist
 * @param { Object } product
 */
export const addToWishList = (product) => (dispatch) => {
    toast.success("Produkt dodano do ulubionych");
    dispatch({ type: types.ADD_TO_WISHLIST, product });
}

/**
 * Remove item from Wishlist
 * @param { String } productId 
 */
export const removeFromWishlist = (product) => (dispatch) => {
    toast.error("Produkt usunięto z ulubionych");
    dispatch({ type: types.REMOVE_FROM_WISHLIST, product });
}

/**
 * Remove item from Wishlist and Add to Cart
 * @param { String } product
 */
export const moveFromWishlistToCart = (product) => (dispatch) => {
    toast.success("Produkt dodano do koszyka");
    // dispatch({ type: types.REMOVE_FROM_WISHLIST, product });
    dispatch({ type: types.ADD_TO_CART, product, qty: 1 });
}

/********************* Compare Action *********************/

/**
 * Add item to Compare List
 * @param { Object } product
 */
export const addToCompare = (productId) => (dispatch) => {
    dispatch({ type: types.ADD_TO_COMPARE, productId });
}

/**
 * Remove item form Compare List
 * @param { String } productId 
 */
export const removeFromCompare = (productId) => (dispatch) => {
    dispatch({ type: types.REMOVE_FROM_COMPARE, productId });
}

/**
 * Clear all compare products
 * 
 */
export const clearAllCompare = () => (dispatch) => {
    dispatch({ type: types.REMOVE_ALL_COMPARE });
}

/********************* Filter Action *********************/

/**
 * Filter by Brand
 * @param { String }  name
 */
export const filterManufacturer = (name) => ({
    type: types.FILTER_MANUFACTURER,
    name
});

export const filterBrandProm = (manufacturerProm) => ({
    type: types.FILTER_MANUFACTURER_PROM,
    manufacturerProm
});

/**
 * Filter by categories
 * @param { Number } category 
 */
export const filterCategories = (category) => ({
    type: types.FILTER_CATEGORIES,
    category
});

/**
 * Filter by categories
 * @param { Number } delivery 
 */
export const filterDelivery = (delivery) => ({
    type: types.FILTER_DELIVERY,
    delivery
});

/**
 * Sort by
 * @param { String } sortBy 
 */
export const filterSort = (sortBy) => ({
    type: types.SORT_BY,
    sortBy
});

/********************* Newsletter Modal Action *********************/
export const hideNewsletter = () => dispatch => {
    dispatch({ type: types.HIDE_NEWSLETTER });
}

/**
 * Clean All Filter
 * 
 */
export const cleanFilter = () => ({
    type: types.FILTER_CLEAN
});

/**
 * Clean Category
 * 
 */
export const cleanCategory = () => ({
    type: types.FILTER_CLEAN_CATEGORY
});

export const getSearchTerm = (searchInput) => dispatch => {
    dispatch({ type: types.ADD_SEARCH_TERM, searchInput });
}