import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getToken, getUser } from '../../utils';

const PrivateClient = ({ children }) => {
  const user = getUser();
  const token = getToken();
  let location = useLocation();

  if (token && (user.role === "client" || user.role === "contractor")) {
    return children;
  }
  
  return <Navigate to='/logowanie' state={location.state} />;
}

export default PrivateClient;