import {
  RECEIVE_BLOG_POSTS,
  RECEIVE_BLOG_CATEGORIES,
  RECEIVE_BLOG_POSTS_BY_CATEGORY,
  RECEIVE_BLOG_POST,
  REFRESH_STORE
} from '../constants/action-types';

const initialState = {
  blogPosts: [],
  blogCategories: [],
  blogPostsByCategory: [],
  blogPost: []
}

const blogReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_BLOG_POSTS:
      return { ...state, blogPosts: action.blogPosts };

    case RECEIVE_BLOG_CATEGORIES:
      return { ...state, blogCategories: action.blogCategories };

    case RECEIVE_BLOG_POSTS_BY_CATEGORY:
      return { ...state, blogPostsByCategory: action.blogPostsByCategory };

    case RECEIVE_BLOG_POST:
      return { ...state, blogPost: action.blogPost };

    case REFRESH_STORE:
      return initialState;

    default:
      return state;
  }
};

export default blogReducer;