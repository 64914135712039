import {
  RECEIVE_MANUFACTURERS,
  REFRESH_STORE
} from '../constants/action-types';

const initialState = {
  manufacturers: []
}

const manufacturersReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_MANUFACTURERS:
      return { ...state, manufacturers: action.manufacturers };

    case REFRESH_STORE:
      return initialState;

    default:
      return state;
  }
};

export default manufacturersReducer;