import {
  GET_PAGE_BY_SLUG,
  REFRESH_STORE
} from '../constants/action-types';

const initialState = {
  pageBySlug: null
}

const cmsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAGE_BY_SLUG:
      return { ...state, pageBySlug: action.pageBySlug };

    case REFRESH_STORE:
      return initialState;

    default:
      return state;
  }
};

export default cmsReducer;