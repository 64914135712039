//PROMO PRODUCTS
export const RECEIVE_PROMO_PRODUCTS = 'RECEIVE_PROMO_PRODUCTS'
export const RECEIVE_PROMO_PRODUCTS_HOME = 'RECEIVE_PROMO_PRODUCTS_HOME'

//MANUFACTURERS
export const RECEIVE_MANUFACTURERS = 'RECEIVE_MANUFACTURERS';

//CATEGORIES
export const RECEIVE_CATEGORIES = 'RECEIVE_CATEGORIES';

//BRANCHES
export const RECEIVE_BRANCHES = 'RECEIVE_BRANCHES';

//PRODUCTS
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
export const RECEIVE_PRODUCTS_BY_CATEGORY = 'RECEIVE_PRODUCTS_BY_CATEGORY';
export const RECEIVE_PRODUCT_BY_SLUG = 'RECEIVE_PRODUCT_BY_SLUG';
export const RECEIVE_PRODUCT_LAST_PRICE = 'RECEIVE_PRODUCT_LAST_PRICE';
export const RECEIVE_PRODUCT_PRICE_HISTORY = 'RECEIVE_PRODUCT_PRICE_HISTORY';
export const RECEIVE_DELIVERY_COST_ON_PRODUCT = 'RECEIVE_DELIVERY_COST_ON_PRODUCT';
export const RECEIVE_SEARCH_PRODUCT = 'RECEIVE_SEARCH_PRODUCT';
export const SHOW_QUICKVIEW = 'SHOW_QUICKVIEW';
export const HIDE_QUICKVIEW = 'HIDE_QUICKVIEW';
export const FETCH_SINGLE_PRODUCT = 'FETCH_SINGLE_PRODUCT';

//BLOG
export const RECEIVE_BLOG_POSTS = 'RECEIVE_BLOG_POSTS';
export const RECEIVE_BLOG_CATEGORIES = 'RECEIVE_BLOG_CATEGORIES';
export const RECEIVE_BLOG_POSTS_BY_CATEGORY = 'RECEIVE_BLOG_POSTS_BY_CATEGORY';
export const RECEIVE_BLOG_POST = 'RECEIVE_BLOG_POST';

//CHECK IF EMAIL EXIST
export const CHECK_IF_EMAIL_EXIST = 'CHECK_IF_EMAIL_EXIST';

//CART
export const ADD_TO_CART = 'ADD_TO_CART';
export const CHANGE_QTY = 'CHANGE_QTY';
export const ADD_TO_PRICING = 'ADD_TO_PRICING';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const REMOVE_FROM_PRICING = 'REMOVE_FROM_PRICING';
export const INCREMENT_QTY = 'INCREMENT_QTY';
export const DECREMENT_QTY = 'DECREMENT_QTY';
export const CLEAR_CART = 'CLEAR_CART';
export const SEND_CLEAR_CART = 'SEND_CLEAR_CART';
export const GET_DELIVERY_COST = 'GET_DELIVERY_COST';
export const FILTER_CART = 'FILTER_CART';

//USERS
export const GET_USER_ADRESSES = 'GET_USER_ADRESSES';
export const GET_ORDERS = 'GET_ORDERS';
export const GET_ORDER = 'GET_ORDER';
export const PROVIDER_CANCEL_ORDER = 'PROVIDER_CANCEL_ORDER';
export const SET_STATE = 'SET_STATE';
export const TIMEOUT_PRICING = 'TIMEOUT_PRICING';
export const GET_VIEW_PROFILE = 'GET_VIEW_PROFILE';
export const GET_CARS = 'GET_CARS';
export const GET_BRANCH_PRODUCTS_LIST = 'GET_BRANCH_PRODUCTS_LIST';
export const GET_PAYMENTS = 'GET_PAYMENTS';
export const GET_PARTNER_PROGRAM_PRODUCTS = 'GET_PARTNER_PROGRAM_PRODUCTS';
export const GET_PARTNER_PROGRAM_CATEGORIES = 'GET_PARTNER_PROGRAM_CATEGORIES';
export const GET_FAVORITE_PRODUCTS = 'GET_FAVORITE_PRODUCTS';
export const ADD_FAVORITE_PRODUCTS = 'ADD_FAVORITE_PRODUCTS';
export const DEL_FAVORITE_PRODUCT = 'DEL_FAVORITE_PRODUCT';

//CMS
export const GET_PAGE_BY_SLUG = 'GET_PAGE_BY_SLUG';

//CONTACT
export const SEND_CONTACT_MESSAGE = 'SEND_CONTACT_MESSAGE';

// Filter Products
export const FILTER_MANUFACTURER = 'FILTER_MANUFACTURER';
export const FILTER_MANUFACTURER_PROM = 'FILTER_MANUFACTURER_PROM';
export const FILTER_CATEGORIES = 'FILTER_CATEGORIES';
export const FILTER_DELIVERY = 'FILTER_DELIVERY';
export const SORT_BY = 'SORT_BY';
export const FILTER_CLEAN = 'FILTER_CLEAN';
export const FILTER_CLEAN_CATEGORY = 'FILTER_CLEAN_CATEGORY';


// WishList
export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST';
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST';

// Compare
export const ADD_TO_COMPARE = 'ADD_TO_COMPARE';
export const REMOVE_FROM_COMPARE = 'REMOVE_FROM_COMPARE';
export const REMOVE_ALL_COMPARE = 'REMOVE_ALL_COMPARE';

// CheckOut Process
export const CHECKOUT_REQUEST = 'CHECKOUT_REQUEST';
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS';
export const CHECKOUT_FAILURE = 'CHECKOUT_FAILURE';

// Demo change
export const HIDE_CART_MODAL = 'HIDE_CART_MODAL';
export const SHOW_CART_MODAL = 'SHOW_CART_MODAL';

// Newsletter PopUp Modal
export const HIDE_NEWSLETTER = 'HIDE_NEWSLETTER';

// Refresh Store
export const REFRESH_STORE = 'REFRESH_STORE';

// Messages
export const RECEIVE_MESSAGES = 'RECEIVE_MESSAGES';
export const FETCH_SINGLE_MESSAGE = 'FETCH_SINGLE_MESSAGE';
export const ADD_MESSAGE = 'ADD_MESSAGE';

// Search term
export const ADD_SEARCH_TERM = 'ADD_SEARCH_TERM';