import {
  RECEIVE_PRODUCTS,
  RECEIVE_PRODUCTS_BY_CATEGORY,
  RECEIVE_PRODUCT_BY_SLUG,
  RECEIVE_PRODUCT_LAST_PRICE,
  RECEIVE_PRODUCT_PRICE_HISTORY,
  RECEIVE_DELIVERY_COST_ON_PRODUCT,
  RECEIVE_SEARCH_PRODUCT,
  SHOW_QUICKVIEW,
  HIDE_QUICKVIEW,
  // FETCH_SINGLE_PRODUCT,
  REFRESH_STORE
} from '../constants/action-types';
// import { findProductIndex } from '../utils';

const initialState = {
  products: [],
  productsHP: [],
  productsByCategory: [],
  productBySlug: null,
  productLastPrice: null,
  productPriceHistory: [],
  productDeliveryCost: null,
  searchProducts: [],
  quickShow: false,
  // single: {},
  // fetchBegin: true
}

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_PRODUCTS:
      return { 
        ...state, 
        products: action.products, 
        productsHP: action.products.filter((a) => 
          a.product_id === 98 ||
          a.product_id === 116 ||
          a.product_id === 1442 ||
          a.product_id === 1441 ||
          a.product_id === 1316 ||
          a.product_id === 1439 ||
          a.product_id === 1418 ||
          a.product_id === 1411 ||
          a.product_id === 1407 ||
          a.product_id === 907 ||
          a.product_id === 175
        )
      };

    case RECEIVE_PRODUCTS_BY_CATEGORY:
      return { ...state, productsByCategory: action.productsByCategory };

    case RECEIVE_PRODUCT_BY_SLUG:
      return { ...state, productBySlug: action.productBySlug };

    case RECEIVE_PRODUCT_LAST_PRICE:
      return { ...state, productLastPrice: action.productLastPrice };

    case RECEIVE_PRODUCT_PRICE_HISTORY:
      return { ...state, productPriceHistory: action.productPriceHistory };

    case RECEIVE_DELIVERY_COST_ON_PRODUCT:
      return { ...state, productDeliveryCost: action.productDeliveryCost };

    case RECEIVE_SEARCH_PRODUCT:
      return { ...state, searchProducts: action.searchProducts };

    // case FETCH_SINGLE_PRODUCT:
    //   if (findProductIndex(state.products, action.productId)) {
    //     const single = state.products.reduce((itemAcc, product) => {
    //       return product
    //     }, [])
    //     return { ...state, single: single };
    //   }
    //   break;

    case SHOW_QUICKVIEW:
      return { ...state, single: action.product, quickShow: true };

    case HIDE_QUICKVIEW:
      return { ...state, quickShow: false };

    case REFRESH_STORE:
      return initialState;

    default:
      return state;
  }
};

export default productReducer;