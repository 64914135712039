import {
  GET_USER_ADRESSES,
  GET_ORDERS,
  GET_ORDER,
  GET_VIEW_PROFILE,
  GET_CARS,
  GET_BRANCH_PRODUCTS_LIST,
  GET_PAYMENTS,
  GET_PARTNER_PROGRAM_PRODUCTS,
  GET_PARTNER_PROGRAM_CATEGORIES,
  GET_FAVORITE_PRODUCTS,
  ADD_FAVORITE_PRODUCTS,
  DEL_FAVORITE_PRODUCT,
  REFRESH_STORE
} from '../constants/action-types';

const initialState = {
  userAddresses: null,
  orders: null,
  order: null,
  userData: null,
  carsFleet: null,
  branchProducts: null,
  payments: [],
  partnerProgramProducts: [],
  partnerProgramCategories: [],
  wishlist: []
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_ADRESSES:
      return { ...state, userAddresses: action.userAddresses };

    case GET_ORDERS:
      return { ...state, orders: action.orders };

    case GET_ORDER:
      return { ...state, order: action.order };

    case GET_VIEW_PROFILE:
      return { ...state, userData: action.viewProfile };

    case GET_CARS:
      return { ...state, carsFleet: action.getCars };

    case GET_BRANCH_PRODUCTS_LIST:
      return { ...state, branchProducts: action.productList };

    case GET_PAYMENTS:
      return { ...state, payments: action.payments };

    case GET_PARTNER_PROGRAM_PRODUCTS:
      return { ...state, partnerProgramProducts: action.ppProducts };

    case GET_PARTNER_PROGRAM_CATEGORIES:
      return { ...state, partnerProgramCategories: action.ppCategories };

    case GET_FAVORITE_PRODUCTS:
      return { ...state, wishlist: action.fp };

    case ADD_FAVORITE_PRODUCTS:
      return { ...state, wishlist: [...state.wishlist, action.fp] };

    case DEL_FAVORITE_PRODUCT:
      return {
        ...state,
        wishlist: state.wishlist.filter(
          a => a.id !== action.fp
        ),
      }

    case REFRESH_STORE:
      return initialState;

    default:
      return state;
  }
};

export default userReducer;