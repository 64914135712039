import React from 'react';
import { Route, Routes } from 'react-router-dom';

import store from '../store';

import { initStickyOffset } from '../utils';

import { HIDE_CART_MODAL, HIDE_QUICKVIEW } from '../constants/action-types';

import PrivateClient from './client/private-client-route';
import PrivateSeller from './seller/private-seller-route';
import PublicRoute from './public-route';

let ProductsPages = React.lazy(() => import('./products-route'));
let LoginRoute = React.lazy(() => import('./login-route'));
let ClientRoute = React.lazy(() => import('./client/client-route'));
let SellerRoute = React.lazy(() => import('./seller/seller-route'));
let HomeRoute = React.lazy(() => import('./home-route'));

export default function Router() {
  store.dispatch({ type: HIDE_CART_MODAL });
  store.dispatch({ type: HIDE_QUICKVIEW });
  initStickyOffset();

  return (
    <React.Suspense fallback={<span></span>}>
      <Routes>
        <Route path='produkty/*' element={<ProductsPages />} />
        <Route path='logowanie/*' element={<PublicRoute><LoginRoute /></PublicRoute>} />
        <Route path='klient/*' element={<PrivateClient><ClientRoute /></PrivateClient>} />
        <Route path='sprzedawca/*' element={<PrivateSeller><SellerRoute /></PrivateSeller>} />
        <Route path='*' element={<HomeRoute />} />
      </Routes>
    </React.Suspense>
  );
}